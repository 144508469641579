.all-bg-green{
    background-color: gray;
}

.contact-icon{
    background-color: rgb(101, 177, 69);
}

.contact-map{
    height: 500px;
    width: 400px;
}



.form-containers {
    
    /* right: 32px;
    z-index: 1000; */
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 450px;
    height: 450px;
  }
  
  .f-forms {
    display: flex;
    flex-direction: column;
  }
  
  .form-sections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding-top: 20px;
  }
  
  .form-groups {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    outline: none;
   
  }
 
  .btn-callbacks {
    margin-top: 30px;
    border: none;
    padding: 10px;
    border-radius: 10px;
  }

  .btn-callbacks:hover{
    background-color: rgb(101, 177, 69) !important;
  }

  .selections {
    margin-top: 20PX;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    background-color: #f1faff;
  }
  
@media screen and (max-width:799px){
  
    .icon-containers{
        display: flex;
    }

    .map-img{
      height: 100%;
      width: 100%;
    }


    .form-containers {
    
      background: rgba(255, 255, 255, 0.9);
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
      width:100%;
      height: 100%;
     
    }

    .form-groups {
      display: flex;
      flex-direction: column;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 10px;
      border: none;
      outline: none;
      width: 100%;
     
    }
   
    .form-sections {
      display: flex;
      gap: 1px;
      flex-direction: column;
      padding-top: 5px;
    }

    .selections {
      /* margin-top: 20PX; */
      /* padding: 10px; */
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 14px;
      background-color: #f1faff;
    }
}