.img-fluid {
    max-width: 100%;
    height: 200px;
}

.calendar-colour{ 
    color:#196b9c !important;
    font-weight: bold; 
    font-size: 15px;
    cursor: pointer;
}

.calendar-clr{
    color: #196b9c !important;
    font-weight: bold;   
}

.calendar-colour:hover
{
    color: rgb(101, 177, 69) !important;
}

.calendar-clr:hover{
    color: rgb(101, 177, 69) !important;
}

.blog-header
{
    font-size: 30px;
    color:#196b9c !important;
    font-weight: bold;
}

.blog-title{
    cursor: pointer;
}

.blog-title:hover{
    color: rgb(101, 177, 69);
}

.blog-titlee{
    cursor: pointer;
}

.blog-titlee:hover{
    color: rgb(101, 177, 69) !important;
}

.blogbtn{
    position: relative;
    left: 500px;
}