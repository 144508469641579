.our-team {
    font-size: 44px;
    font-weight: 700;
    /* word-spacing: 2px; */
    text-align: center;
    padding: 90px 0px 90px 0px;
}
.ht-shape-btn {
    position: absolute;
    right: -1px;
    top: -3px;
    width: auto;
    height: auto;
    border-radius: 0 30px 0 0;
  }
  .ht-shape-btn svg path {
    fill: #f1faff !important;
  }
  .featured-number {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
  }
  .featured-number  {
    background: white;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .shattle{
  position: relative;
  }
  .shattle:hover::before {
    border-radius: 30px;
    position: absolute;
    content: '';
    background: linear-gradient(0deg, #196b9c 50%, #FFFFFF00 75.55%);
    opacity: 0.8;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
  }


  @media screen and (max-width :799px)
  {
    .shattle{
      height: 100%;
      width: 600px;
      }

      .elementor-widget-container{
        display: flex;
        justify-content: center;
      }

      .our-team-dr{
          position: absolute;
          bottom: 55px;
          right: 20px;
          text-align: left;
      }
      .our-team {
        font-size: 25px;
        font-weight: 700;
        text-align: center;
        padding: 10px 0px 10px 0px;
    }
  
  }