.footer-btm {
    background-color: black;
    color: white;
}

.foot-logo {
    height: 60px;
    width: 200px;
    background-color: #F1FAFF;
    margin-left: 10px;
}

.text-alignment {
    text-align: justify;
}

.search-container {
    position: relative;
    width: 100%;
    max-width: 400px;
}

.search-input {
    width: 100%;
    padding: 10px;
    padding-right: 40px;
    box-sizing: border-box;
    border-radius: 50px 50px 50px 50px;
    border: none;
    outline: none;
    border: 1px solid #ccc;
    font-size: 16px;
}

.search-icon {
    position: absolute;
    right: 30px;
    top: 43%;
    transform: translateY(-50%);
    /* pointer-events: none; */
    color: black;
    font-size: 20px;
    font-weight: bolder;
}

.foot-middle-part {
    background-color: #196B9C;
    border-radius: 20px;
    padding: 10px 20px;
}

.links-padding {
    padding-right: 15px;
}

.footer-useful-links {
    color: unset !important;
    text-decoration: none !important;
}

.privacy {
    font-weight: bold;
    color: #196B9C;
    font-size: 25px;
}

.email-add {
    padding-top: 10px;
}


@media screen and (max-width:799px) {
    .links {
        display: flex;
        justify-content: left !important;
        gap: 22px;
    }
}