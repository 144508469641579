.whatsapp-button {
    position: fixed;
    z-index: 99999999999;
    bottom: 10px;
    right: 10px;
 
    border: none;
    padding: 5px 10px;
    /* Adjust padding as needed */
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center horizontally */
    color: green;
    /* Set the color to green */
    background: transparent;
    border-radius: 10%;
  }
 
  .whatsapp-button:hover {
    background-color: transparent;
    cursor: pointer;
 
  }
 
  .whatsapp-icon {
    margin-right: 0px;
    /* Adjust margin as needed */
    height: 50px;
    width: 50px;
    font-size: 44px;
    /* Increase the icon size */
    position: relative;
    /* Ensure relative positioning for fine adjustments */
    top: 2px;
    /* Adjust vertical position as needed */
  }
  
  /* scrool  */
  .scrool-up-icon {
    border: 1px solid #196b9c;
    position: fixed;
    bottom: 62px;
    right: 24px;
    background: white;
    border-radius: 5px;
    z-index: 9999;
    cursor: pointer;
    /* padding-right: 10px; */
  }
  .scrool {
    background: #196b9c;
    color: white;
    width: 40px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }