.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.head-back-img {
  height: auto !important;
}

.breadcrumb-hover {
  font-weight: 600 !important;
  color: #208b8c !important;

}

@media only screen and (max-width: 600px) {
  .service-title p {
    font-size: 13px !important;
    margin-left: -50px;
    margin-right: 32px;
  }

  .contentheading {
    text-align: left;
    font-size: 24px !important;
  }

  #rotatingText {
    margin-top: 4px !important;
  }

  /* 
  .featured-desc .c-head.our-team-dr {
    bottom: 50px !important;
    right: 20px !important;
  } */

  .afpajdpjo {
    display: block;
    position: relative;
    z-index: 9999;
  }

  .c-para {
    bottom: 10px !important;
  }

  .video-button .ht-shape-btn {
    left: 146px !important;
  }

  .video-button a {
    left: 195px !important;
  }

  .c-img {
    margin-bottom: 80px !important;
  }

  .video-button {
    position: relative;
  }
}

@media only screen and (max-width: 375px) {
  #rotatingText {
    margin-top: 11px !important;
  }

  .video-button {
    position: relative;
  }

}

@media only screen and (max-width: 320px) {
  #rotatingText {
    margin-top: 18px !important;
  }

  .video-button {
    position: relative;
  }

  .c-head {
    font-size: 17px !important;
    margin-left: -29px !important;
  }

  .c-para {
    bottom: 10px !important;
  }

  .video-button .ht-shape-btn {
    left: 92px !important;
  }

  .video-button a {
    left: 140px !important;
  }
}

.testi-p {
  font-size: 24px;
  line-height: 38px;
  margin: 0px 0px 20px;
  padding: 50px;
  text-align: left;
}

.lfde {
  padding: 90px 0px 90px 0px;
}

.conatiner {
  margin-right: 40px;
  background: white;

}

.ht-shape-btns {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  border-radius: 0 30px 0 0;
}

.ht-shape-btns svg path {
  fill: #f1faff !important;
}

.testimonial-quote-home {
  background: #196b9c;
  position: absolute;
  right: 15px;
  top: 15px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
}

.privacy-police p {
  text-align: left;
}

.privacy-police li {
  text-align: left;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: rgb(255 255 255) !important;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #f1faff
}

.copy-write span a {
  text-decoration: none;
  padding-left: 10px;
  color: white;
  font-weight: 600;
}

.footer-useful-links p {
  font-size: 16px;
  font-weight: 600;
  width: fit-content;
}

.footer-useful-links p:hover {
  color: #65b145;
}

.text-start:hover {
  color: #65b145;
}

.fw-semibold h2 {
  font-size: 15px;
}

.headingg-text a:hover {
  color: #65b145 !important;
}

.c-head:hover {
  color: #65b145 !important
}

strong:hover {
  color: #65b145 !important
}

.text-alignment.off a {
  text-decoration: none;
  color: white
}

.text-alignment.off a:hover {
  color: #65b145 !important
}

.copy-write span a:hover {
  color: #65b145 !important
}

.card-title>a:hover {
  color: #65b145 !important
}

.featured-item.style-1.our-latest-blog-cor:hover {
  color: #65b145 !important
}

.elementor-widget-container.this-service-for-margin:hover {
  color: #65b145 !important
}

.fw-semibold>div:hover {
  color: #65b145 !important
}

.frjjjfs .sohfwwww {
  display: flex !important;
}


@media only screen and (max-width: 700px) and (min-width: 600px) {
  .cor-paragraph {
    top: -100px;
  }

  .carousel-caption {
    position: absolute;
    bottom: -4.75rem !important;
  }

  .cor-paragraph {
    top: -100px !important;
  }

  .main {
    top: -15px !important;
  }

  .main img {
    margin-left: -25px;
    margin-top: -135px !important;
  }

  .video-button .ht-shape-btn {
    position: relative;
    left: 237px !important;
  }

  .video-button a {
    left: 285px !important;
  }

  .afpajdpjo {
    display: block !important;
    position: relative;
    z-index: 9999;
  }

  .header {
    display: none !important;
  }

  .cor-heading {
    font-size: 45px !important;
    margin-bottom: 3em !important;
  }

  .video-button {
    position: relative;
  }
}

.dfdsf {
  position: sticky;
  top: 120px;
}

.grger {
  position: sticky;
  top: 120px;
}