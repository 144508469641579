.cor-heading {
  font-size: 61px;
  margin-bottom: 2em;
  margin-right: 0.5em;
  width: 484px;
  text-align: left !important;
  font-weight: 700;
}

.carousel-item {
  position: relative;
}

.cor-top-home {
  position: relative;
  top: -48px;
}

.carousel-item::before {
  border-radius: 42px;
  position: absolute;
  content: '';
  background: linear-gradient(90deg, #196b9c 50%, #FFFFFF00 75.55%);
  opacity: 0.7;
  width: 81%;
  height: 100%;
  top: 0;
  left: 0;
  /* z-index: 9; */
}

.cor-paragraph {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0;
  max-width: 55%;
  padding-right: 300px;
  margin-top: -105px;
  position: absolute;
  /* top: 150px; */
  top: 12px;
  left: 205px;
  text-align: justify;
}

.carousel-caption {
  left: 7% !important;
}

.carousel-control-prev-icon {
  background-image: url('../../public/Images//arrow.png') !important;
  height: 15px !important;
  width: 15px !important;
  background-size: cover;
}

.carousel-control-next-icon {
  background-image: url('../../public/Images//right-arrow.png') !important;
  height: 15px !important;
  width: 15px !important;
  background-size: cover;
}

.carousel-control-next {
  width: 40px !important;
  background: white !important;
  height: 36px !important;
  top: 45% !important;
  opacity: unset !important;
  border-radius: 50%;
  right: -11px;
}

.carousel-control-prev {
  width: 40px !important;
  left: -11px;
  background: white !important;
  height: 36px !important;
  top: 45% !important;
  opacity: unset !important;
  border-radius: 50%;
}

.card-h {
  padding: 100px 0px 100px 0px;
}

.arrow-right-explore {
  height: 50px;
  width: 50px;
}



@media (max-width: 799px) {


  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 15px !important;
    /* Adjust the icon size for smaller screens */
    width: 20px !important;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 5% !important;
    /* Adjust the width */
    height: 5% !important;
    top: 50% !important;
  }

  .carousel-control-prev {
    left: 10px;
    /* Adjust the position */
  }

  .carousel-control-next {
    right: 10px;
    /* Adjust the position */
  }



  .carousel-item img {
    height: 300px;
    /* Adjust the image height */
    width: 100%;
    /* Make sure the image takes full width */
  }

  .carousel-caption {
    position: absolute;
    bottom: -11.75rem;
    font-size: 10px;
    color: #fff;
  }

  .featured-item {
    width: 90%;
    height: 250px;
  }

  .carousel-content {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  .main {
    margin-bottom: 20px;
    /* Space between the SVG and text on smaller screens */
  }

  .arrow-right-explore {
    width: 50px !important;
    /* Adjust icon size */
    height: 50px !important;
    /* Adjust icon size */
  }

  #rotatingText {
    width: 100px;
    /* Adjust SVG size */
    height: 100px;
    /* Adjust SVG size */
    margin-top: 17px;
  }

  .main img {
    margin-left: -25px;
    margin-top: -197px;
  }

  .cor-paragraph {
    font-size: 10px;
    max-width: 70%;
    padding-right: 0;
    margin: 0;
    position: relative;
    top: -204px;
    left: 100px;
    line-height: 14px;
  }

  .main {
    display: grid;
    align-items: center;
    /* justify-items: center; */
    position: relative;
    top: -95px
  }

  .rotating-text-mobile-res {
    position: absolute;
    top: -134px;
  }

  .card-h {
    padding: 30px 0px 30px 0px;
  }

  .c-head {
    margin-left: -78px;
    margin-top: -25px;
    font-size: 18px;
  }

  .c-img {
    margin-bottom: 53px;
    margin-right: -45px;
  }

  .c-para {
    text-align: justify;
    font-size: 13px;
    width: 155px;
    margin-left: -23px;
    margin-top: -13px;
  }

  .theme-title h2 {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
  }

  .phk p {
    margin: -31px 0 25px;
    line-height: 1.6;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
  }
}

/*  */
.main {
  display: grid;
  align-items: center;
  /* justify-items: center; */
  position: relative;
  top: -95px
}

.text {
  font-weight: 600;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  font-family: 'Kanit', sans-serif;
  font-size: 17px;
  fill: white;

}

#rotatingText {
  animation-name: rotate-circle;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rotate-circle {
  to {
    transform: rotate(1turn);
  }
}

.main img {
  position: absolute;
  /* animation-name: rotate-circle;
  animation-direction: reverse;
  animation-duration: 5s; */
  /* animation-timing-function: linear;
  animation-iteration-count: infinite; */
  filter: drop-shadow(0px 0px 5px #333);
  padding: 12px;
  border-radius: 50px;
  left: 50px;
  backdrop-filter: brightness(120%) contrast(100%) saturate(102%) blur(10px) hue-rotate(0deg);
}

.elementor-widget-wrap>.elementor-element {
  width: 100%;
}

.elementor-widget-container {
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
}

.featured-item.style-1 {
  position: relative;
  background: #ffffff;
  padding: 50px;
  border-radius: 30px;
  z-index: 9;
}

.ht-shape-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  border-radius: 0 30px 0 0;
}

.ht-shape-btn svg path {
  fill: #f1faff !important;
}

.featured-number {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

.featured-number span {
  background: white;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 700;
  font-size: 25px;
}

.featured-item.style-1 .featured-icon {
  display: inline-block;
  position: absolute;
  right: 50px;
  bottom: 50px;
}

.featured-item.style-1 .featured-icon i {
  font-size: 70px;
  line-height: 70px;
  color: black;
  display: inline-block;
}

.featured-item.style-1 .featured-desc {
  padding-right: 100px;
}

.featured-item.style-1 .featured-title {
  border-bottom: 1px solid grey;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.c-img {
  margin-bottom: 80px;
}

.c-para {
  text-align: justify;
}

.c-head {
  width: 250px;
  text-align: left !important;
}

@media only screen and (max-width: 600px) {
  .cor-heading {
    font-size: 25px;
    margin-bottom: 10em;
    width: 300px;

  }

  .main img {
    margin-top: -162px;
  }

  .c-head div p {
    margin-bottom: 0px !important;
  }

  .carousel-control-prev {
    left: 0px;
  }

  .carousel-control-next {
    right: 0px;
  }

}

@media only screen and (max-width: 375px) {
  .main img {
    margin-top: -145px;
  }
}

@media only screen and (max-width: 320px) {
  .main img {
    margin-top: -133px;
  }
}