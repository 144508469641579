.theme-title h6 {
    position: relative;
    font-family: var(--themeht-typography-body-font-family);
    margin-bottom: 10px;
    text-transform: uppercase;
    color: var(--themeht-text-color);
    font-weight: 500;
    font-size: 13px;
    width: fit-content;
    line-height: 1;
    background: white;
    padding: 8px 20px;
    border-radius: 100px;
    text-align: left;
}

.theme-title h2 {
    font-size: 44px;
    font-weight: 700;
    /* word-spacing: 2px; */
    text-align: left;
}

.phk p {
    margin: 0 0 25px;
    line-height: 1.6;
    font-size: 17px;
    text-align: left;
    font-weight: 500;
}

.primary-btn,
a.primary-btn {
    background: #196b9c;
    color: white;
    padding: 17px 6px;
    border-radius: 50px;
    width: 154px;
}

.home-about-navigate {
    color: unset;
    text-decoration: none;
}

.attachment-doctean-1200x500 {
    width: 930px;
    border-radius: 30px;
    height: 430px;

}

.imh {
    padding: 0px 0px 40px 0px;
}

.ht-img-active .elementor-widget-container {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    visibility: visible;
    transform: skewY(0);
}

.ht-img-animation .elementor-widget-container {
    transition: 1s ease-out !important;
    transition-delay: 0.1s !important;
    overflow: hidden;
    /* clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    visibility: hidden; */
    border-radius: 30px;
}

.elementor-10 .elementor-element.elementor-element-5972a68 img {
    border-radius: 20px 20px 20px 20px;
}

.ht-img-active .elementor-widget-container img {
    transform: scale(1);
    transition: 3s ease-out;
}

.elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.elementor-element.elementor-element-9e6a173>.elementor-widget-container {
    margin: -64px 0px 0px 0px;
}

.video-button a {
    background: white;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 40px;
    z-index: 9;
    position: relative;
}

.video-button {
    position: absolute;
}

.bvsvsd {
    padding-bottom: 90px;
}

.video-button .ht-shape-btn {
    position: relative;
    left: 0px;
    top: -111px;
    width: auto;
    height: auto;
    border-radius: 0 30px 0 0;
    transform: rotate(180deg);
}

.ht-img-active .elementor-widget-container img {
    transform: scale(1);
    transition: 3s ease-out;
}

.ReactModal__Overlay--after-open {
    z-index: 9999 !important;
}

@media screen and (max-width :799px) {
    .main-second-img {
        border-radius: 30px !important;
    }

    .attachment-doctean-1200x500 {
        width: 100% !important;
        height: auto !important;
    }

    .attachment-doctean-700x600 {
        height: auto !important;
        width: 100% !important;

    }

    .phk p {
        margin: 0 0 15px;
        font-size: 15px;
        text-align: left;
        font-weight: 500;
    }

    .theme-title h2 {
        font-size: 25px;
        font-weight: 700;
        /* word-spacing: 2px; */
        text-align: left;
    }

    .imh {
        padding: 0px 0px 10px 0px;
    }


    .video-button .ht-shape-btn {
        position: relative;
        left: 94px;
        top: -95px;
        width: auto;
        height: auto;
        border-radius: 0 30px 0 0;
        transform: rotate(90deg);
    }

    .video-button a {
        background: white;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 40px;
        z-index: 9;
        position: relative;
        left: 143px;
        top: -1px;
    }

    .wp-image-835 {
        margin-top: 20px;
    }

    .column-gap-default-in-main-section {
        margin: 0 !important;
    }
}

@media only screen and (max-width: 600px) {
    .elementor-widget-container video {
        height: 150px !important;
        margin-top: 10px;

    }
}