/* General Styles */
/* .phone-box {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.phone-icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.frist-number,
.second-number {
  margin: 0;
  font-size: 1rem;
} */
/* .header {
  position: relative;
  top: 0;
  width: 100%;
  padding: 20px;
  background-color: white;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
} */

.header.fixed {
  position: fixed;
  top: 0;
  width: 100%;
}

.header {
  position: sticky;
  top: 0;
  height: 97px;
  z-index: 9999
}

.headingg-text {
  font-size: 15px !important;
}

.afpajdpjo {
  display: none;
}

.callback-button {
  color: white;
  background-color: #196b9c;
  border-radius: 50px;
  height: 40px;
  width: 180px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.callback-button:hover {
  background-color: #65b145;
}

.ht-navigation {
  height: 51px;
}

.para-button {
  height: 57px;
  margin-top: -20px;
}

/* .navbar-nav.navbar-nav-scroll {
  background-color: white !important;
  width: 70% !important;
  padding-top: 16px;
  padding-bottom: 20px;
  border-top-left-radius: 30px;
}

.nav-content-color {
  color: #196b9c;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s;
} */

/* .nav-content-color:hover,
.nav-content-color:focus {
  color: #65b145 !important;
} */

.form-container {
  position: absolute;
  top: 100px;
  right: 32px;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 404px;
}

.f-form {
  display: flex;
  flex-direction: column;
}

.form-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.selection {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #f1faff;
}

.btn-callback {
  border: none;
  padding: 7px;
}

.cont-top-p {
  display: flex;
  gap: 20px;
  padding: 10px 83px;
  flex-wrap: wrap;
}

.top-h {
  display: flex;
  gap: 10px;
  align-items: center;

}

.icons {
  font-size: 20px;
  margin-top: -5px;

}

@media(max-width:768px) {
  .cont-top-p {
    padding: 10px 20px;
    flex-direction: column;
    gap: 10px;
  }
}

/* .offcanvas-body .nav-link {
  font-size: 1.5rem;
  margin: 15px 0;
  text-align: center;
} */
.mobile-res {
  display: none;
}


/* Media query for tablets and smaller devices */
@media (max-width: 992px) {
  .navbar-nav.navbar-nav-scroll {
    width: 100% !important;
  }

  .form-container {
    width: 90%;
    right: 5%;
    top: 10px;
  }
}

@media (max-width: 768px) {
  .navbar-nav.navbar-nav-scroll {
    width: 100% !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .callback-button {
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .phone-box {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 10px;
  }

  .phone-icon {
    margin: 0;
  }

  .form-container {
    width: 90%;
    left: 5%;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    position: relative;
    /* Change from fixed to relative */
    margin-bottom: 10px;
    /* Add margin to separate from bottom content */
  }

  .form-section {
    grid-template-columns: 1fr;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .d-flex {
    flex-direction: column;
    /* flex-direction: row; */
    align-items: center;
  }

  .mobile-res {
    display: block;
  }
}

@media (max-width: 768px) {
  .mobile-res {
    display: block !important;
  }
}

/* ======================================== */
.header-top {
  padding: 10px 0;
}

.p-0 {
  padding: 0 !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-right: auto;
  margin-left: auto;
}

#header-wrap {
  position: relative;
  z-index: 999;
  padding: 0 30px;
}

.col-header-headache {
  flex: 1 0 0%;
}

.row-header-headache>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  /* padding-right: 15px !important; 
    padding-left: 15px !important;   */
  margin-top: 0 !important;
}

.main-nav {
  height: 50px !important;
  margin-bottom: -30px;
}

.ht-navigation {
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  border-top-right-radius: 30px;
}

.header .navbar-brand.logo svg {
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0px;
  margin-top: -4px;
  right: -30px;
  transform: rotate(0deg) scale(1.01);
}

img,
svg {
  vertical-align: middle;
}

.header svg path {
  fill: #f1faff;
}

.navbar-brand.logo {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  /* padding: 15px 30px 15px 0; */
  background-color: #f1faff;
  border-bottom-right-radius: 30px;
  position: relative;
}

.ht-mobile-menu-bg {
  display: none;
}

.phone-no {
  display: block;
  margin-bottom: 10px;
  width: 116px;
}

.navigation-top {
  background-color: white;
  padding: 9px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 30px;
}

@media (min-width: 1200px) {
  .main-nav .menu {
    flex-direction: row;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
}

.main-nav>ul>li {
  margin: 0 15px;
}

.closepanel {
  display: none;
}

.main-nav li {
  position: relative;
  list-style-type: none;
}

.closepanel i {
  display: inline-block;
  font-size: 40px;
  color: #196b9c;
  line-height: 1;
}

.header-number {
  display: flex;
  align-items: center;
  border-right: 2px solid rgb(220, 216, 216);
  padding-right: 20px;
  margin-right: 20px;
}

.header-number i {
  font-size: 34px;
  color: black;
  line-height: 1;
  margin-right: 5px;
}

.header-number span {
  display: block;
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 3px;
  font-family: sans-serif;
}

.ht-nav-toggle {
  position: relative;
  width: 40px;
  height: 40px;
}

.ht-nav-toggle {
  cursor: pointer;
  text-decoration: none;
}

.ht-nav-toggle {
  cursor: pointer;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.header-right {
  position: relative;
  padding: 0px 0 0px 20px;
  display: inline-block;
  border-bottom-left-radius: 55px;
  background-color: #f1faff;
}

.primary-btn,
a.primary-btn {
  background-color: #196b9c;
  color: white;
}

.themeht-btn span {
  -webkit-transition: all 0s ease-in-out 0s;
  -moz-transition: all 0s ease-in-out 0s;
  -ms-transition: all 0s ease-in-out 0s;
  -o-transition: all 0s ease-in-out 0s;
  transition: all 0s ease-in-out 0s;
}

.themeht-btn svg {
  margin-left: 10px;
}

.header-right>svg {
  display: block;
  width: 30px;
  height: 51px;
  position: absolute;
  top: 0px;
  left: -30px;
  transform: rotate(90deg) scale(1.01);
}

img,
svg {
  vertical-align: middle;
}

/* .header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: white;
  transition: background-color 0.3s ease;
} */

.header.scrolled {
  background-color: #196B9C;
  /* Change to desired color on scroll */
}

.header .navbar-brand img {
  max-width: 85%;
  height: 65px;
}

/* Mobile responsive styling */
@media (max-width: 799px) {
  .header {
    background-color: #f8f9fa;
    /* Change to a different color if needed */
  }

  .header .navbar-brand img {
    max-width: 70%;
    height: 55px;
  }

  /* .header .right-nav, .header .main-nav {
    display: none; 
  } */
  .header {
    position: block !important;
  }

  .header .ht-mobile-menu-bg {
    display: block;
    /* Display mobile menu toggle */
  }
}

/* More specific mobile responsive styling */
@media (max-width: 499px) {
  .header .navbar-brand img {
    max-width: 60%;
    height: 45px;
  }

  .header {
    display: none !important;
  }

  .afpajdpjo {
    display: block;
  }
}