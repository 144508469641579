.marquee-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  /* padding: 0px 0px 90px 0px; */
}

.marquee-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}

.marquee-text {
  display: flex;
  font-size: 100px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  line-height: 130px;
  animation: scroll-text 80s linear infinite;
}

.marquee-text span,
.marquee-text i {
  display: inline-block;
  margin-right: 10px;
}

.marquee-text img {
  width: 120px;
  margin-bottom: 10px;
  margin-left: 10px;
}

@keyframes scroll-text {
  0% {
    transform: translateX(20%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* @keyframes color-change {
  0% {
    color: #196b9c;
    
  }

  100% {
    color: #65b145;
    
  }
} */

.marquee-text {
  /* animation: scroll-text 60s linear infinite, color-change 5s linear infinite alternate; */
}

@media (max-width: 799px) {
  .marquee-text {
    font-size: 30px;
    line-height: 65px;
  }

  .marquee-text img {
    width: 20px;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .marquee-container {
    padding: 0px 0px 20px 0px;
    /* margin-top: -70px; */
  }
}