.cont-top-p {
    display: flex;
    /* gap:20px; */
    justify-content: space-between;
    padding: 10px 83px;
    flex-wrap: wrap;
}

.top-h {
    display: flex;
    gap: 10px;
    align-items: center;

}

.icons {
    font-size: 20px;
    margin-top: -5px;

}

@media(max-width:768px) {
    .cont-top-p {
        padding: 10px 20px;
        flex-direction: column;
        gap: 10px;
    }
}