.abtus-heading {
    position: relative;
    right: 200px;
    color: white;
}

.back-img-head {
    position: relative;
    bottom: 30px;
    right: 475px;
}

.faq-heading {
    position: relative;
    right: 500px;
    top: 50px;
    color: white;
}

.homebar-faq {
    position: relative;
    right: 500px;
    top: 60px;
    color: white;
}

.other-service-content {
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start; */
    text-align: left;
    margin-left: 90px;
    cursor: pointer;
}

.other-services {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.other-service-deptname {
    cursor: pointer;
}

.other-service-deptname:hover {
    color: rgb(101, 177, 69);
}

.service-image {
    height: 200px;
    width: 100%;
}

@media screen and (max-width:799px) {

    .abtus-heading {
        position: relative;
        right: 20px;
        bottom: 50px;
    }

    .back-img-head {
        position: relative;
        bottom: 20px;
        right: 100px;
    }

    .faq-heading {
        position: relative;
        right: 90px;
        top: 50px;
        color: white;
    }

    .homebar-faq {
        position: relative;
        right: 79px;
        top: 50px;
        color: white;
    }

    .other-service-content {
        /* display: flex;
    flex-direction: column;
    justify-content: flex-start; */
        text-align: left;
        margin-left: 30px;
    }
}

.slick-prev,
.slick-next {
    color: #65b145;
    background: #65b145 !important;
}