/* .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
} */


/* .carousel-container {
    width: 100%;
}

.carousel-item {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.head-back-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
} */

/* .owl-previous{
    color: unset;
}

.owl-next{
    color: unset;
} */


/* 
.back-img-head{
    position: absolute;
    top: 50%;
} */

.home-icon{
    margin-top: 30px;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
    background: 0 0;
    color: unset !important;
    display: none;
    /* visibility: hidden; */
    border: none;
    padding: 0 !important;
    font: inherit;
}


.img {
    height: 300px;
    width: 100%;
}

.video {
    height: 300px;
    width: 100%;
}

@media screen and (max-width:799px)
{
    .home-icon{
        margin-top: 30px;
    }
    .img {
        height: 150px;
        width: 100%;
    }
    .video {
        height: 175px;
        width: 100%;
    }
    
}