.ht-shape-btn{
    fill:black !important;
}
.ht-shape-btn .left-side {
    right: auto;
    top: 0px;
    left: -29px;
    transform: rotate(-90deg);
}

.s-para {
    margin-left: -140px;
    margin-top: 13px;
    font-size: 15px;
    font-weight: 600;
   
}
.service-para{
    padding: 5px 15px;
    background-color: #f1faff; 
    border-radius: 10px; 
}
.service-title{
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 25px;
}
.featured-service-icon {
    position: absolute;
    top: 0;
    right: 340px;
    z-index: 9;
    height: 64px;
    width: 64px;
    border-radius: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
    display: block !important;
}
.owl-prev{
    display: none !important;
}
.owl-next{
    display: none !important;

}
.up-arrow-button-res{
    transform: rotate(90deg);
bottom: -252px ;
/* right: unset; */
left: -71.5%;
}

@media screen and (max-width:799px) {
    .c-img {
        margin-bottom: 35px;
    }
    .this-service-for-margin{
        margin-right: 30px;
    }
    .fea-mobile-res-style{
        padding: 0 !important;
        width: 241px !important;
        border-radius: 20px !important;
        height: 350px !important;
    }
    .service-image-mobile-res{
        width: 241px !important;
        border-radius: 20px !important;
        height: 251px !important;
    }
    /* .ht-btn-shape-mobile-res{
        left: 0 !important;
        right: auto !important;
        transform:rotate(-90deg) !important;
    } */
    .up-arrow-button-res{
        transform: rotate(90deg) !important;
    bottom: -252px !important;
    right: unset !important;
    left:-45% !important;
    }
    .featured-icon-service-mobile-res{
        right: -8px !important;
        top: 288px !important;
    }
    .service-title {
        padding-bottom: 8px !important;
        padding-top: 33px !important;
        margin-left: 40px !important;
    }
    .s-para {
        margin-left: -38px !important;
        margin-top: 19px !important;
    }
    .c-head{
        font-size: 17px !important;
        margin-left: -29px !important;
    }
}