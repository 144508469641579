.latest-blog-theme1 {
  padding: 90px 0px 90px 0px;
}

.latest-blog-mobile-res-section {
  padding-left: 5px;
}

.blog-theme1 {
  font-size: 55px;
  font-weight: 700;
  color: #196b9c;
}

.service-title h3 {
  font-size: 20px;
}

.service-title p {
  font-size: 20px;
}

.service-title h2 {
  font-size: 20px;
}

@media screen and (max-width:799px) {
  .latest-blog-mobile-res {
    flex-direction: row;
    align-items: center;
    width: 250px;
  }

  .latest-blog-mobile-res-section {
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;

  }

  .date-text {
    text-align: center;
    font-size: 13px;
  }

  .blog-theme1 {
    font-size: 25px;
    font-weight: 700;
    color: #196b9c;
  }

  .latest-blog-theme1 {
    padding: 20px 0px 10px 0px;
  }

  .our-latest-blog-cor {
    width: 265px !important;
    border-radius: 20px !important;
    height: 400px !important;
  }

  .our-latest-blog-img {
    width: 265px !important;
    border-radius: 20px !important;
    height: 250px !important;
  }

  .badge-text-wrap {
    width: 113% !important;
    margin-left: -14px !important;
    font-weight: 700;
  }

  .latest-blog-svg {
    transform: rotate(90deg) !important;
    bottom: -292px !important;
    right: unset !important;
    left: -52% !important;
  }

  .f-m-s-i {
    right: -8px !important;
    top: 333px !important;
  }

}